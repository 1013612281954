import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { doc, getDoc, setDoc, arrayUnion } from 'firebase/firestore'
import { db } from '../config/firebaseConfig' // Firebase configuration
import { FiDownload, FiEye } from 'react-icons/fi'
import { useParams } from 'wouter'
import Loading from '../components/Loading'
import {
    findUserByEmail,
    getLastThreeMonths,
    getLastThreeYears,
    industryCategories,
    isAdmin,
    sendStatusChangeEmail,
} from '../utils/helpers'
import moment from 'moment'

const initialStyles = {
    businessName: {},
    dba: {},
    referrer: {},
    referrerEmail: {},
    useOfFunds: {},
    address: {},
    city: {},
    state: {},
    zip: {},
    tel: {},
    dateIncorporated: {},
    industry: {},
    businessDescription: {},
    ownerName: {},
    ownerTel: {},
    ownerEmail: {},
    ownerDOB: {},
    ownerSSN: {},
    pctOwnership: {},
    driversLicenseFront: {},
    driversLicenseBack: {},
    bankStatement1: {},
    bankStatement2: {},
    bankStatement3: {},
    businessApplication: {},
}

const DealDetailPage = ({ user, userInfo }) => {
    const [deal, setDeal] = useState(null)
    const [dealStatus, setDealStatus] = useState('')
    const [additionalFiles, setAdditionalFiles] = useState({
        bankStatementsAdditional: [],
        applicationsAdditional: [],
        taxReturnsAdditional: [],
        miscellaneousAdditional: [],
    })
    const [loading, setLoading] = useState(false)
    const [submitStatusMessage, setSubmitStatusMessage] = useState('')
    const [isEditing, setIsEditing] = useState(false)
    const [editableFields, setEditableFields] = useState({
        referrer: '',
        referrerEmail: '',
        useOfFunds: '',
        businessName: '',
        dba: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        tel: '',
        dateIncorporated: '',
        industry: '',
        businessDescription: '',
        ownerName: '',
        ownerTel: '',
        ownerEmail: '',
        ownerDOB: '',
        ownerSSN: '',
        pctOwnership: '',
        driversLicenseFront: '',
        hasLargeLenderBalances: '',
        bankStatement1: '',
        bankStatement2: '',
        bankStatement3: '',
        businessApplication: '',
    })

    const [hasChanged, setHasChanged] = useState(false)

    const [formData, setFormData] = useState({
        referrer: '',
        referrerEmail: '',
        useOfFunds: '',
        businessName: '',
        dba: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        tel: '',
        dateIncorporated: '',
        industry: '',
        businessDescription: '',
        ownerName: '',
        ownerTel: '',
        ownerEmail: '',
        ownerDOB: '',
        ownerSSN: '',
        pctOwnership: '',
        driversLicenseFront: '',
        hasLargeLenderBalances: '',
        bankStatement1: '',
        bankStatement2: '',
        bankStatement3: '',
        businessApplication: '',
    })

    const [message, setMessage] = useState('')
    const [messageSubmitStatus, setMessageSubmitStatus] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [dealUser, setDealUser] = useState({})

    const [selectedStatus, setSelectedStatus] = useState(dealStatus)
    const [statusChanged, setStatusChanged] = useState(false)

    const [urlParams] = useState(new URLSearchParams(window.location.search))
    // eslint-disable-next-line
    const messageTimestamp = urlParams.get('timestamp')
    const { dealId } = useParams()

    const [amountInput, setAmountInput] = useState('')
    const [isSubmittingAmount, setIsSubmittingAmount] = useState(false)

    // Add this to the status change section in DealDetailPage
    const handleAmountSubmission = async () => {
        if (!amountInput || isNaN(amountInput)) {
            alert('Please enter a valid amount')
            return
        }

        setIsSubmittingAmount(true)
        try {
            // Update the deal with the approved amount
            await setDoc(
                doc(db, 'dealsDev', dealId),
                {
                    amountApproved: parseFloat(amountInput),
                    status: selectedStatus,
                },
                { merge: true }
            )

            // Create message object
            const messageObj = {
                dealId: dealId,
                message: `APPROVED: $${parseFloat(
                    amountInput
                ).toLocaleString()} - ${
                    deal.businessName
                }\nStatus Description: The deal is approved for $${parseFloat(
                    amountInput
                ).toLocaleString()}.`,
                timestamp: new Date().toISOString(),
                from: 'system',
                dealName: deal.businessName,
                status: selectedStatus,
                type: 'status_update',
            }

            // Update deal with status message
            await setDoc(
                doc(db, 'dealsDev', dealId),
                {
                    brokerMessages: arrayUnion(messageObj),
                    lastMessageTimestamp: new Date().toISOString(),
                },
                { merge: true }
            )

            // Also update ISO's messages collection
            if (deal.referrerEmail && dealUser?.uid) {
                await setDoc(
                    doc(db, 'usersDev', dealUser.uid),
                    {
                        messages: arrayUnion(messageObj),
                    },
                    { merge: true }
                )
            }

            await sendStatusChangeEmail(
                deal.referrer,
                deal.referrerEmail,
                dealUser.email,
                selectedStatus,
                deal.businessName
            )

            setAmountInput('')
            setIsSubmittingAmount(false)
            setDealStatus(selectedStatus)
            setStatusChanged(false)
            setSubmitStatusMessage('Status and amount updated successfully.')
        } catch (error) {
            console.error('Error updating amount:', error)
            setSubmitStatusMessage('Error updating amount')
            setIsSubmittingAmount(false)
        }
    }

    useEffect(() => {
        setSelectedStatus(dealStatus)
    }, [dealStatus])

    const handleStatusChange = (event) => {
        const newStatus = event.target.value
        setSelectedStatus(newStatus)
        setStatusChanged(newStatus !== dealStatus)
    }

    const updateDealStatus = async () => {
        setLoading(true)

        try {
            // Always update the status in Firestore
            await setDoc(
                doc(db, 'dealsDev', dealId),
                {
                    status: selectedStatus,
                },
                { merge: true }
            )

            // Skip additional operations if status is "Question for Broker"
            if (selectedStatus !== 'Question for Broker') {
                // Get status message based on new status
                const statusMessage = getStatusMessage(
                    selectedStatus,
                    deal.businessName
                )

                // Create message object for ISO notification
                const messageObj = {
                    dealId: dealId,
                    message: statusMessage,
                    timestamp: new Date().toISOString(),
                    from: 'system',
                    dealName: deal.businessName,
                    status: selectedStatus,
                    type: 'status_update',
                }

                // Update deal with status message
                await setDoc(
                    doc(db, 'dealsDev', dealId),
                    {
                        brokerMessages: arrayUnion(messageObj),
                        lastMessageTimestamp: new Date().toISOString(),
                    },
                    { merge: true }
                )

                // Also update ISO's messages collection
                if (deal.referrerEmail && dealUser?.uid) {
                    await setDoc(
                        doc(db, 'usersDev', dealUser.uid),
                        {
                            messages: arrayUnion(messageObj),
                        },
                        { merge: true }
                    )
                }

                await sendStatusChangeEmail(
                    deal.referrer,
                    deal.referrerEmail,
                    dealUser.email,
                    selectedStatus,
                    deal.businessName
                )
            }

            setDealStatus(selectedStatus)
            setStatusChanged(false)
            setSubmitStatusMessage('Status updated successfully.')
            setLoading(false)
        } catch (error) {
            console.error('Error updating status:', error)
            setSubmitStatusMessage('Error updating status')
            setLoading(false)
        }
    }

    const getStatusMessage = (status, merchantName, declinedMsg = '') => {
        const statusMessages = {
            Pending: `New Submission - ${merchantName} - Status Description: New deal submission received, pending entry completion.`,
            'In Progress': `IN PROGRESS: Credit Review - ${merchantName}\nStatus Description: Deal is entered; Credit and statements are being reviewed for pricing.`,
            'Under Review': `UNDER REVIEW: Full Analysis - ${merchantName}\nStatus Description: Application is undergoing comprehensive underwriting review.`,
            Declined: `DECLINED: Decision Notice - ${merchantName}\nStatus Description: After careful review of the submitted application and supporting documentation, we are unable to approve this request at this time.\n\nReason for decline: ${declinedMsg}`,
            'Closing Docs Out': `CLOSING DOCS: Ready for Review - ${merchantName}\nStatus Description: An offer has been accepted and the contracts are now available to review or to be sent for e-signature.`,
            'Closing Docs Signed': `DOCS SIGNED: Under Review - ${merchantName}\nStatus Description: Contracts have been signed and the application is being reviewed by one of our internal underwriters.`,
            Approved: `APPROVED: Ready to Fund - ${merchantName}\nStatus Description: The deal is fully approved without any funding stipulations.`,
            Funded: `FUNDED: Wire Sent - ${merchantName}\nStatus Description: The proceeds for the client have been sent via wire transfer. Those wire details will be provided in a separate email. If this funding required payoffs, those have been processed as well.`,
        }

        return (
            statusMessages[status] ||
            `Status Update: ${status} - ${merchantName}`
        )
    }

    const handleSendMessage = async () => {
        if (!message.trim()) {
            alert('Please enter a message')
            return
        }

        setIsSubmitting(true)
        setMessageSubmitStatus('')

        try {
            // Generate a unique message ID using timestamp + random string
            const messageId = `msg_${Date.now()}_${Math.random()
                .toString(36)
                .substring(2, 15)}`
            const timestamp = new Date().toISOString()

            const messageObj = {
                id: messageId,
                dealId: dealId,
                message: `Question for Broker: BROKER ACTION: Response Required - ${message}`,
                timestamp: timestamp,
                from: user.email,
                dealName: deal.businessName,
                status: 'Question for Broker',
                read: false,
            }

            // Update deal with message
            await setDoc(
                doc(db, 'dealsDev', dealId),
                {
                    brokerMessages: arrayUnion(messageObj),
                    lastMessageTimestamp: timestamp,
                    status: selectedStatus,
                },
                { merge: true }
            )

            // Update user's messages
            await setDoc(
                doc(db, 'usersDev', dealUser.uid),
                {
                    messages: arrayUnion(messageObj),
                },
                { merge: true }
            )

            await sendStatusChangeEmail(
                deal.referrer,
                deal.referrerEmail,
                dealUser.email,
                selectedStatus,
                deal.businessName,
                message
            )

            setMessage('')
            setMessageSubmitStatus('Message sent successfully!')
            setTimeout(() => setMessageSubmitStatus(''), 3000)
        } catch (error) {
            console.error('Error sending message:', error)
            setMessageSubmitStatus('Error sending message')
        } finally {
            setIsSubmitting(false)
        }
    }

    const fetchDeal = async () => {
        setLoading(true)
        const dealDoc = await getDoc(doc(db, 'dealsDev', dealId))
        if (dealDoc.exists()) {
            const dealData = dealDoc.data()
            setDeal(dealData)
            setEditableFields(dealData)
            setDealStatus(dealData.status)

            // Set up additional files from Firestore data (if present)
            setAdditionalFiles({
                bankStatementsAdditional:
                    dealData.bankStatementsAdditional || [],
                applicationsAdditional: dealData.applicationsAdditional || [],
                taxReturnsAdditional: dealData.taxReturnsAdditional || [],
                miscellaneousAdditional: dealData.miscellaneousAdditional || [],
            })

            setFormData({
                ...dealData,
                bankStatementsAdditional:
                    dealData.bankStatementsAdditional || [],
                applicationsAdditional: dealData.applicationsAdditional || [],
                taxReturnsAdditional: dealData.taxReturnsAdditional || [],
                miscellaneousAdditional: dealData.miscellaneousAdditional || [],
            })
            setLoading(false)
        } else {
            setLoading(false)
            console.error('Deal not found')
        }
    }

    useEffect(() => {
        fetchDeal()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (deal?.userEmail) {
            findUserByEmail(deal.userEmail).then((user) => {
                setDealUser(user)
            })
        }
        // eslint-disable-next-line
    }, [deal])

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (!file || !(file instanceof Blob)) {
                // If the file is not provided or not a Blob, return 'N/A'
                resolve('N/A')
                return
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result.split(',')[1])
            reader.onerror = (error) => reject(error)
        })
    }

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailPattern.test(email)
    }

    const validateField = (name, value) => {
        let isValid = false

        // Handle undefined values or file inputs
        if (typeof value === 'string') {
            value = value.trim()
        }

        switch (name) {
            case 'referrerEmail':
            case 'ownerEmail':
                isValid = validateEmail(value)
                break
            case 'zip':
                isValid = value?.length === 5
                break
            case 'tel':
            case 'ownerTel':
                isValid = value?.length === 10
                break
            case 'ownerSSN':
                isValid = value?.length === 9 && parseInt(value)
                break
            case 'businessName':
            case 'referrer':
            case 'useOfFunds':
            case 'address':
            case 'city':
            case 'state':
            case 'dateIncorporated':
            case 'industry':
            case 'businessDescription':
            case 'ownerName':
            case 'ownerDOB':
            case 'pctOwnership':
                isValid = typeof value === 'string' && value?.trim() !== ''
                break
            case 'driversLicenseFront':
            case 'bankStatement1':
            case 'bankStatement2':
            case 'bankStatement3':
            case 'businessApplication':
                isValid = !!value
                break
            default:
                // eslint-disable-next-line
                isValid = true
        }
    }
    // eslint-disable-next-line
    const [fieldStyles, setFieldStyles] = useState(initialStyles)

    const handleEditToggle = () => {
        setIsEditing(!isEditing)
    }

    const handleFieldChange = (e) => {
        const { name, value } = e.target

        // Special handling for zip code
        if (name === 'zip') {
            // Remove any non-digit characters
            const numericValue = value.replace(/\D/g, '')
            // Limit to 5 digits
            const truncatedValue = numericValue.slice(0, 5)
            setEditableFields((prev) => ({ ...prev, [name]: truncatedValue }))
            return
        }

        setEditableFields((prev) => ({ ...prev, [name]: value }))
    }

    const saveEdits = async () => {
        try {
            setLoading(true)
            await setDoc(doc(db, 'dealsDev', dealId), editableFields, {
                merge: true,
            })
            setDeal(editableFields)
            setSubmitStatusMessage('Information updated successfully.')
            setIsEditing(false)
            setLoading(false)
        } catch (error) {
            console.error('Error saving edits:', error)
        }
    }

    const renderDocumentRow = (
        label,
        fileKey,
        categoryName,
        isMandatory = false
    ) => {
        const fileUrl = deal?.[fileKey]?.url ? deal?.[fileKey]?.url : null

        // Get timestamp for the file
        const getFileTimestamp = () => {
            const timestampKey = `${fileKey}`
            return deal?.[timestampKey]?.timestamp || null
        }

        const formatTimestamp = (timestamp) => {
            if (!timestamp) return 'Not uploaded'
            return moment(timestamp).format('MMM DD, YYYY [at] hh:mm A')
        }

        const handleViewClick = (e) => {
            e.preventDefault()
            alert(
                'Your file has been downloaded and is ready to view. Please use a PDF viewer.'
            )
            window.open(fileUrl, '_blank')
        }

        // Check if this fileKey corresponds to an additional files category
        const isAdditionalCategory = [
            'bankStatementsAdditional',
            'applicationsAdditional',
            'taxReturnsAdditional',
            'miscellaneousAdditional',
        ].includes(fileKey)

        return (
            <div className='flex flex-col mb-4 w-full'>
                <div className='flex flex-col md:flex-row justify-between gap-4 p-4 border rounded-lg bg-white'>
                    <div className='flex-1'>
                        <span className='font-bold block'>
                            {label}
                            {isMandatory && '*'}
                        </span>
                        {fileUrl && (
                            <span className='text-sm text-gray-600 block mt-1'>
                                Uploaded: {formatTimestamp(getFileTimestamp())}
                            </span>
                        )}
                    </div>
                    <div className='flex flex-col space-x-4 items-center w-full max-w-[500px]'>
                        {fileUrl && !isAdditionalCategory ? (
                            <div className='w-full flex flex-col md:flex-row gap-2'>
                                <a
                                    href={fileUrl}
                                    onClick={handleViewClick}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='w-[210px] cursor-pointer inline-flex items-center justify-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-transparent hover:bg-gray-100 transition'
                                >
                                    <FiEye className='mr-1' /> View
                                </a>
                                <a
                                    href={fileUrl}
                                    download={deal?.[fileKey]?.name}
                                    className='w-full cursor-pointer inline-flex items-center justify-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-transparent hover:bg-gray-100 transition'
                                >
                                    <FiDownload className='mr-1' /> Download
                                </a>
                            </div>
                        ) : (
                            <div className='w-full flex items-center'>
                                <CustomDragAndDrop
                                    fileName={fileKey}
                                    categoryName={categoryName}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    const renderAdditionalFiles = (files, category, categoryName) => {
        if (!files || files.length === 0) {
            return 'No additional files uploaded'
        }

        const handleViewClick = (e, fileUrl) => {
            e.preventDefault()
            alert(
                'Your file has been downloaded and is ready to view. Please use a PDF viewer.'
            )
            window.open(fileUrl, '_blank')
        }

        // Get timestamps array based on category
        const getTimestamps = () => {
            switch (category) {
                case 'bankStatementsAdditional':
                    return deal?.bankStatementsAdditionalTimestamps || []
                case 'applicationsAdditional':
                    return deal?.applicationsAdditionalTimestamps || []
                case 'taxReturnsAdditional':
                    return deal?.taxReturnsAdditionalTimestamps || []
                case 'miscellaneousAdditional':
                    return deal?.miscellaneousAdditionalTimestamps || []
                default:
                    return []
            }
        }

        const timestamps = getTimestamps()

        // Function to format timestamp
        const formatTimestamp = (timestamp) => {
            if (!timestamp) return 'N/A'
            return moment(timestamp).format('MMM DD, YYYY [at] hh:mm A')
        }

        return (
            <div className='flex flex-col space-y-4'>
                {files.map((file, index) => (
                    <div key={index} className='border rounded-lg p-4 bg-white'>
                        <div className='flex flex-col md:flex-row justify-between gap-4'>
                            <div className='flex-1'>
                                <span className='font-bold mb-2 block'>
                                    {categoryName} File {index + 1}
                                </span>
                                <span className='text-sm text-gray-600 block'>
                                    Uploaded:{' '}
                                    {formatTimestamp(timestamps[index])}
                                </span>
                            </div>
                            <div className='flex gap-2 md:w-[400px]'>
                                <a
                                    href={file}
                                    onClick={(e) => handleViewClick(e, file)}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='flex-1 cursor-pointer inline-flex items-center justify-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-transparent hover:bg-gray-100 transition'
                                >
                                    <FiEye className='mr-1' /> View
                                </a>
                                <a
                                    href={file}
                                    download={file}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='flex-1 cursor-pointer inline-flex items-center justify-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-transparent hover:bg-gray-100 transition'
                                >
                                    <FiDownload className='mr-1' /> Download
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    const CustomDragAndDrop = ({ fileName, categoryName }) => {
        const [isDragging, setIsDragging] = useState(false)

        const handleDragOver = (e) => {
            e.preventDefault()
            setIsDragging(true)
        }

        const handleDragLeave = () => {
            setIsDragging(false)
        }

        const handleDrop = (e) => {
            e.preventDefault()
            setIsDragging(false)
            const object = {
                target: {
                    files: e.dataTransfer.files,
                    id: fileName,
                    value: '',
                },
            }
            handleFileUpload(object) // Trigger file upload on drop
        }

        const handleFileChange = (e) => {
            handleFileUpload(e) // Trigger file upload on file change
        }

        // Determine styles based on dragging state
        const getBorderStyle = () => {
            return isDragging ? 'border-blue-400 bg-blue-50' : 'border-gray-300'
        }

        return (
            <div className='flex items-center gap-2 mb-4 w-fit md:lg:w-full ml-auto'>
                {/* + Add Button */}
                <div>
                    <label
                        htmlFor={`${fileName}`}
                        className='w-full md:lg:w-[150px] cursor-pointer inline-flex items-center justify-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-transparent hover:bg-gray-100 transition'
                    >
                        + Add{' '}
                        {fileName.includes('Additional')
                            ? `${categoryName} Files`
                            : ''}
                    </label>
                    <input
                        type='file'
                        onChange={handleFileChange}
                        className='hidden'
                        id={`${fileName}`}
                    />
                </div>
                {/* Drag and Drop Area */}
                <div
                    className={`hidden md:flex lg:flex items-center justify-center border border-dashed ${getBorderStyle()} p-2 rounded-md w-full transition-colors duration-200 cursor-pointer`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <p className='text-sm text-gray-500'>
                        Drag & drop file here
                    </p>
                </div>
            </div>
        )
    }

    const handleFileUpload = (e) => {
        const { id: name, value, files } = e.target

        const data = files
            ? { ...formData, [name]: files[0] }
            : { ...formData, [name]: value }

        if (!hasChanged) {
            setHasChanged(true)
        }

        if (files) {
            setFormData((prevState) => ({
                ...prevState,
                [name]: files[0],
            }))
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }))
        }

        validateField(name, value || files?.[0]?.name) // Run validation on change

        handleSubmit(data)
    }

    const handleSubmit = async (formData) => {
        try {
            setLoading(true)
            setSubmitStatusMessage('Submitting records...')

            const lastThreeMonths = getLastThreeMonths()
            const lastThreeYears = getLastThreeYears()
            const isFileObject = (file) => file instanceof File
            let hasNewFiles = false

            const convertFilesToBase64 = async (data) => {
                const convertedData = { ...data }
                const fieldsToConvert = [
                    'driversLicenseFront',
                    'driversLicenseBack',
                    'businessApplication',
                    'microLoanApplication',
                    'quidityLibriumSBA',
                    'quidityJumboLoan',
                    'bankStatementsAdditional',
                    'applicationsAdditional',
                    'taxReturnsAdditional',
                    'miscellaneousAdditional',
                    `businessTaxExtension${new Date().getFullYear() - 1}`,
                    `personalTaxExtension${new Date().getFullYear() - 1}`,
                    `plStatement${new Date().getFullYear() - 1}`,
                    `accountsReceivable${new Date().getFullYear() - 1}`,
                    `accountsPayables${new Date().getFullYear() - 1}`,
                ]

                for (const field of fieldsToConvert) {
                    if (isFileObject(data[field])) {
                        hasNewFiles = true
                        convertedData[field] = {
                            name: field,
                            content: await fileToBase64(data[field]),
                            type: data[field]?.type,
                        }
                    }
                }

                for (const { monthName, year } of lastThreeMonths) {
                    const key = `bankStatement${monthName}${year}`
                    if (isFileObject(data[key])) {
                        hasNewFiles = true
                        convertedData[key] = {
                            name: key,
                            content: await fileToBase64(data[key]),
                            type: data[key]?.type,
                        }
                    }
                }

                for (const year of lastThreeYears) {
                    const businessTaxKey = `taxReturn${year}`
                    const personalTaxKey = `personalTaxReturn${year}`
                    if (isFileObject(data[businessTaxKey])) {
                        hasNewFiles = true
                        convertedData[businessTaxKey] = {
                            name: businessTaxKey,
                            content: await fileToBase64(data[businessTaxKey]),
                            type: data[businessTaxKey]?.type,
                        }
                    }
                    if (isFileObject(data[personalTaxKey])) {
                        hasNewFiles = true
                        convertedData[personalTaxKey] = {
                            name: personalTaxKey,
                            content: await fileToBase64(data[personalTaxKey]),
                            type: data[personalTaxKey]?.type,
                        }
                    }
                }

                return convertedData
            }

            const formDataObject = await convertFilesToBase64(formData)
            const modifiedFields = {}
            const fieldsToCheck = [
                'referrer',
                'referrerEmail',
                'useOfFunds',
                'businessName',
                'dba',
                'address',
                'city',
                'state',
                'zip',
                'tel',
                'dateIncorporated',
                'industry',
                'businessDescription',
                'ownerName',
                'ownerTel',
                'ownerEmail',
                'ownerDOB',
                'ownerSSN',
                'pctOwnership',
                'hasLargeLenderBalances',
            ]

            fieldsToCheck.forEach((field) => {
                if (formDataObject[field] !== deal?.[field]) {
                    modifiedFields[field] = formDataObject[field]
                }
            })

            Object.assign(modifiedFields, formDataObject)

            const response = await axios.post(
                'https://us-central1-quidity-app.cloudfunctions.net/modifyDeal',
                {
                    // const response = await axios.post('http://127.0.0.1:5001/quidity-app/us-central1/modifyDeal', {
                    formData: formDataObject,
                    userId: dealUser.uid,
                    userEmail: dealUser.email,
                    docId: dealId,
                    userInfo,
                }
            )

            if (hasNewFiles) {
                await axios.post('https://eo5udo9oq3rjgqi.m.pipedream.net', {
                    textContent: `New Docs have been uploaded. ISO NAME: ${dealUser.companyName} for ${deal.businessName}`,
                })

                const isoName = dealUser.companyName
                const isoContact = dealUser.telephone

                await axios.post('https://eo3g4kfpldaav1h.m.pipedream.net', {
                    isoEmail: dealUser.email,
                    businessName: deal.businessName,
                    isoName,
                    isoContact,
                    date: `${moment().format('MM/DD/YYYY')}`,
                })

                // Clear the URL parameters after successful upload
                window.history.pushState({}, '', `/deal/modify/${dealId}`)
            }

            setSubmitStatusMessage(response.data.message)
            await fetchDeal()
            setLoading(false)
            window.location.reload()
        } catch (error) {
            console.error('Error submitting form:', error)
            setSubmitStatusMessage('Error submitting form')
            setLoading(false)
        }
    }

    // Usage of the helper functions
    const lastThreeMonths = getLastThreeMonths()
    const lastThreeYears = getLastThreeYears()

    if (loading || deal === null) {
        return (
            <div className='h-screen'>
                <Loading />
            </div>
        )
    }

    return (
        <div className='container mx-auto py-8 w-[95%] md:w-[70%] lg:w-[70%] mt-[200px]'>
            <h2 className='text-3xl font-bold mb-4'>
                {isEditing ? (
                    <input
                        type='text'
                        name='businessName'
                        value={
                            editableFields?.businessName || deal.businessName
                        }
                        onChange={handleFieldChange}
                        className='border p-2 rounded-md'
                    />
                ) : (
                    'Business Name: ' + deal?.businessName
                )}
            </h2>
            {/* Status Change */}
            {isAdmin(user) && (
                <section className='mb-8 flex gap-2 items-center'>
                    <h3 className='text-xl font-bold'>Deal Status:</h3>
                    <select
                        value={selectedStatus}
                        onChange={handleStatusChange}
                        className='p-2 border rounded-md cursor-pointer'
                    >
                        <option value='Pending'>Pending</option>
                        <option value='In Progress'>In Progress</option>
                        <option value='Under Review'>Under Review</option>
                        <option value='Question for Broker'>
                            Question for Broker
                        </option>
                        <option value='Declined'>Declined</option>
                        <option value='Closing Docs Out'>
                            Closing Docs Out
                        </option>
                        <option value='Closing Docs Signed'>
                            Closing Docs Signed
                        </option>
                        <option value='Approved'>Approved</option>
                        <option value='Funded'>Funded</option>
                        <option value='Initial Docs Sent'>
                            Initial Docs Sent
                        </option>
                        <option value='Awaiting Transcripts'>
                            Awaiting Transcripts
                        </option>
                        <option value='Bank Review'>Bank Review</option>
                    </select>
                    <button
                        onClick={updateDealStatus}
                        disabled={
                            !statusChanged ||
                            selectedStatus === 'Question for Broker' ||
                            selectedStatus === 'Approved'
                        }
                        className='px-4 py-2 bg-themeColorPrimary text-white rounded-md hover:bg-opacity-90 disabled:opacity-50'
                    >
                        Update Status
                    </button>
                </section>
            )}

            {selectedStatus === 'Approved' && (
                <div className='flex flex-col gap-2 mb-8'>
                    <div className='flex items-center gap-4'>
                        <input
                            type='number'
                            value={amountInput}
                            onChange={(e) => setAmountInput(e.target.value)}
                            placeholder='Enter approved amount'
                            className='themed-input-edit flex-1'
                            disabled={isSubmittingAmount}
                        />
                        <button
                            onClick={handleAmountSubmission}
                            disabled={isSubmittingAmount || !amountInput}
                            className='px-4 py-2 bg-themeColorPrimary text-white rounded-md hover:bg-opacity-90 disabled:opacity-50'
                        >
                            {isSubmittingAmount
                                ? 'Updating...'
                                : 'Update Amount'}
                        </button>
                    </div>
                </div>
            )}

            {selectedStatus === 'Question for Broker' && isAdmin(user) && (
                <div className='flex flex-col gap-2 mb-8'>
                    <div className='flex items-center gap-4'>
                        <input
                            type='text'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder='Please type your message'
                            className='themed-input-edit flex-1'
                            disabled={isSubmitting}
                        />
                        <button
                            onClick={handleSendMessage}
                            disabled={isSubmitting}
                            className='px-4 py-2 bg-themeColorPrimary text-white rounded-md hover:bg-opacity-90 disabled:opacity-50'
                        >
                            {isSubmitting ? 'Sending...' : 'Send'}
                        </button>
                    </div>
                    {messageSubmitStatus && (
                        <div
                            className={`text-sm p-4 rounded-md ${
                                messageSubmitStatus.includes('Error')
                                    ? 'bg-red-100 border border-red-600 text-red-600 font-semibold'
                                    : 'bg-green-100 border border-green-800 text-green-800 font-semibold'
                            }`}
                        >
                            {messageSubmitStatus}
                        </div>
                    )}
                </div>
            )}

            <div className='mb-4 flex flex-col md:flex-row w-full justify-between gap-8 p-6 border border-gray-200 rounded-lg bg-gray-50'>
                {/* Business Information Column */}
                <section className='w-full md:w-1/2'>
                    <h2 className='text-lg font-bold mb-4 border-b border-gray-300 pb-2'>
                        Business Information:
                    </h2>
                    <div className='space-y-4'>
                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Referrer Name:
                            </label>
                            {isEditing ? (
                                <input
                                    type='text'
                                    name='referrer'
                                    value={
                                        editableFields.referrer || deal.referrer
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.referrer}
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Referrer Email:
                            </label>
                            {isEditing ? (
                                <input
                                    type='email'
                                    name='referrerEmail'
                                    value={
                                        editableFields.referrerEmail ||
                                        deal.referrerEmail
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.referrerEmail}
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Industry:
                            </label>
                            {isEditing ? (
                                <select
                                    name='industry'
                                    value={
                                        editableFields.industry || deal.industry
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white cursor-pointer'
                                >
                                    <option value=''>Select Industry</option>
                                    {Object.keys(industryCategories).map(
                                        (ind) => (
                                            <option key={ind} value={ind}>
                                                {ind}
                                            </option>
                                        )
                                    )}
                                </select>
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.industry}
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Description:
                            </label>
                            {isEditing ? (
                                <input
                                    type='text'
                                    name='businessDescription'
                                    value={
                                        editableFields.businessDescription ||
                                        deal.businessDescription
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.businessDescription}
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Address:
                            </label>
                            {isEditing ? (
                                <div className='space-y-2'>
                                    <div>
                                        <label className='text-sm text-gray-600'>
                                            Street Address:
                                        </label>
                                        <input
                                            type='text'
                                            name='address'
                                            value={
                                                editableFields.address ||
                                                deal.address
                                            }
                                            onChange={handleFieldChange}
                                            className='w-full p-2 border rounded bg-white'
                                        />
                                    </div>
                                    <div>
                                        <label className='text-sm text-gray-600'>
                                            City:
                                        </label>
                                        <input
                                            type='text'
                                            name='city'
                                            value={
                                                editableFields.city || deal.city
                                            }
                                            onChange={handleFieldChange}
                                            className='w-full p-2 border rounded bg-white'
                                        />
                                    </div>
                                    <div className='flex gap-2'>
                                        <div className='w-1/2'>
                                            <label className='text-sm text-gray-600'>
                                                State:
                                            </label>
                                            <select
                                                name='state'
                                                value={
                                                    editableFields.state ||
                                                    deal.state
                                                }
                                                onChange={handleFieldChange}
                                                className='w-full p-2 border rounded bg-white cursor-pointer'
                                            >
                                                <option value=''>
                                                    Select State
                                                </option>
                                                {[
                                                    'AL',
                                                    'AK',
                                                    'AZ',
                                                    'AR',
                                                    'CA',
                                                    'CO',
                                                    'CT',
                                                    'DE',
                                                    'FL',
                                                    'GA',
                                                    'HI',
                                                    'ID',
                                                    'IL',
                                                    'IN',
                                                    'IA',
                                                    'KS',
                                                    'KY',
                                                    'LA',
                                                    'ME',
                                                    'MD',
                                                    'MA',
                                                    'MI',
                                                    'MN',
                                                    'MS',
                                                    'MO',
                                                    'MT',
                                                    'NE',
                                                    'NV',
                                                    'NH',
                                                    'NJ',
                                                    'NM',
                                                    'NY',
                                                    'NC',
                                                    'ND',
                                                    'OH',
                                                    'OK',
                                                    'OR',
                                                    'PA',
                                                    'RI',
                                                    'SC',
                                                    'SD',
                                                    'TN',
                                                    'TX',
                                                    'UT',
                                                    'VT',
                                                    'VA',
                                                    'WA',
                                                    'WV',
                                                    'WI',
                                                    'WY',
                                                ].map((state) => (
                                                    <option
                                                        key={state}
                                                        value={state}
                                                    >
                                                        {state}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='w-1/2'>
                                            <label className='text-sm text-gray-600'>
                                                ZIP Code:
                                            </label>
                                            <input
                                                type='text'
                                                name='zip'
                                                value={
                                                    editableFields.zip ||
                                                    deal.zip
                                                }
                                                onChange={handleFieldChange}
                                                className='w-full p-2 border rounded bg-white'
                                                pattern='[0-9]{5}'
                                                maxLength='5'
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {`${deal.address}, ${deal.city}, ${deal.state} ${deal.zip}`}
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Incorporated on:
                            </label>
                            {isEditing ? (
                                <input
                                    type='date'
                                    name='dateIncorporated'
                                    value={
                                        editableFields.dateIncorporated ||
                                        deal.dateIncorporated
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                    max='9999-12-31'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.dateIncorporated}
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                {'MCA>$50k:'}
                            </label>
                            {isEditing ? (
                                <input
                                    type='text'
                                    name='hasLargeLenderBalances'
                                    value={
                                        editableFields.hasLargeLenderBalances ||
                                        deal.hasLargeLenderBalances
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.hasLargeLenderBalances}
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Use of funds:
                            </label>
                            {isEditing ? (
                                <input
                                    type='text'
                                    name='useOfFunds'
                                    value={
                                        editableFields.useOfFunds ||
                                        deal.useOfFunds ||
                                        'N/A'
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.useOfFunds || 'N/A'}
                                </div>
                            )}
                        </div>
                    </div>
                </section>

                {/* Owner Information Column */}
                <section className='w-full md:w-1/2'>
                    <h2 className='text-lg font-bold mb-4 border-b border-gray-300 pb-2'>
                        Owner Information
                    </h2>
                    <div className='space-y-4'>
                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>Owner:</label>
                            {isEditing ? (
                                <input
                                    type='text'
                                    name='ownerName'
                                    value={
                                        editableFields.ownerName ||
                                        deal.ownerName
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.ownerName}
                                </div>
                            )}
                        </div>

                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>Email:</label>
                            {isEditing ? (
                                <input
                                    type='email'
                                    name='ownerEmail'
                                    value={
                                        editableFields.ownerEmail ||
                                        deal.ownerEmail
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                    max='9999-12-31'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.ownerEmail}
                                </div>
                            )}
                        </div>

                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Date of Birth:
                            </label>
                            {isEditing ? (
                                <input
                                    type='date'
                                    name='ownerDOB'
                                    value={
                                        editableFields.ownerDOB || deal.ownerDOB
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                    max='9999-12-31'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.ownerDOB}
                                </div>
                            )}
                        </div>

                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>SSN:</label>
                            {isEditing ? (
                                <input
                                    type='text'
                                    name='ownerSSN'
                                    value={
                                        editableFields.ownerSSN || deal.ownerSSN
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                    maxLength='9'
                                    pattern='[0-9]{9}'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.ownerSSN}
                                </div>
                            )}
                        </div>

                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Telephone:
                            </label>
                            {isEditing ? (
                                <input
                                    type='tel'
                                    name='ownerTel'
                                    value={
                                        editableFields.ownerTel || deal.ownerTel
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                    maxLength='10'
                                    pattern='[0-9]{10}'
                                />
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.ownerTel}
                                </div>
                            )}
                        </div>

                        <div className='flex flex-col'>
                            <label className='font-semibold mb-1'>
                                Ownership:
                            </label>
                            {isEditing ? (
                                <select
                                    name='pctOwnership'
                                    value={
                                        editableFields.pctOwnership ||
                                        deal.pctOwnership
                                    }
                                    onChange={handleFieldChange}
                                    className='w-full p-2 border rounded bg-white'
                                    required
                                >
                                    <option value=''>Select Percentage</option>
                                    <option value='10'>10%</option>
                                    <option value='20'>20%</option>
                                    <option value='30'>30%</option>
                                    <option value='40'>40%</option>
                                    <option value='50'>50%</option>
                                    <option value='60'>60%</option>
                                    <option value='70'>70%</option>
                                    <option value='80'>80%</option>
                                    <option value='90'>90%</option>
                                    <option value='100'>100%</option>
                                </select>
                            ) : (
                                <div className='p-2 bg-white border rounded'>
                                    {deal.pctOwnership}%
                                </div>
                            )}
                        </div>
                    </div>
                    {isAdmin(user) && (
                        <div className='flex justify-end mt-6'>
                            <button
                                className={`px-6 py-2.5 rounded-md font-medium transition-colors duration-200 ${'bg-themeColorPrimary text-white hover:bg-opacity-90'}`}
                                onClick={
                                    isEditing ? saveEdits : handleEditToggle
                                }
                            >
                                {isEditing ? (
                                    loading ? (
                                        <span className='flex items-center gap-2'>
                                            <svg
                                                className='animate-spin h-4 w-4'
                                                viewBox='0 0 24 24'
                                            >
                                                <circle
                                                    className='opacity-25'
                                                    cx='12'
                                                    cy='12'
                                                    r='10'
                                                    stroke='currentColor'
                                                    strokeWidth='4'
                                                    fill='none'
                                                />
                                                <path
                                                    className='opacity-75'
                                                    fill='currentColor'
                                                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                                />
                                            </svg>
                                            Saving...
                                        </span>
                                    ) : (
                                        'Save Changes'
                                    )
                                ) : (
                                    'Modify'
                                )}
                            </button>
                        </div>
                    )}
                </section>
            </div>

            {/* Documents Section */}
            <section className='mb-8'>
                {/* <h3 className="text-xl font-bold mb-4 underline">Documents</h3> */}

                {/* Bank Statements */}
                <h4 className='text-lg font-bold mb-2 underline'>
                    Bank Statements
                </h4>
                {lastThreeMonths.map((monthData, index) =>
                    renderDocumentRow(
                        `Bank Statement ${monthData.monthName} ${monthData.year}`,
                        `bankStatement${monthData.monthName}${monthData.year}`,
                        true
                    )
                )}
                {/* Additional File Upload Sections */}
                {renderAdditionalFiles(
                    additionalFiles?.bankStatementsAdditional || [],
                    'bankStatementsAdditional',
                    'Bank'
                )}
                {renderDocumentRow('', 'bankStatementsAdditional', 'Bank')}

                {/* Applications */}
                <h4 className='text-lg font-bold mt-8 mb-2 underline'>
                    Applications
                </h4>
                {renderDocumentRow(
                    'Business Application',
                    'businessApplication',
                    true
                )}
                {renderDocumentRow(
                    'Quidity Librium Application',
                    'quidityLibriumSBA'
                )}
                {renderDocumentRow(
                    'Quidity Jumbo Loan Application',
                    'quidityJumboLoan'
                )}
                {renderAdditionalFiles(
                    additionalFiles?.applicationsAdditional || [],
                    'applicationsAdditional',
                    'App'
                )}
                {renderDocumentRow('', 'applicationsAdditional', 'App')}

                {/* Tax Documents */}
                <h3 className='text-lg underline font-bold mb-4'>
                    Tax Returns:
                </h3>

                {/* Business Tax Returns */}
                <h4 className='text-md font-bold mb-2 underline'>
                    Business Tax Returns
                </h4>
                {lastThreeYears.map((year) =>
                    renderDocumentRow(
                        `Business Tax Return ${year}`,
                        `taxReturn${year}`,
                        true
                    )
                )}
                {renderAdditionalFiles(
                    additionalFiles?.taxReturnsAdditional || [],
                    'taxReturnsAdditional',
                    'Tax'
                )}
                {renderDocumentRow('', 'taxReturnsAdditional', 'Tax')}

                {/* Personal Tax Returns */}
                <h4 className='text-md font-bold mt-6 mb-2 underline'>
                    Personal Tax Returns
                </h4>
                {lastThreeYears.map((year) =>
                    renderDocumentRow(
                        `Personal Tax Return ${year}`,
                        `personalTaxReturn${year}`,
                        true
                    )
                )}
                {renderAdditionalFiles(
                    additionalFiles?.taxReturnsAdditional || [],
                    'taxReturnsAdditional',
                    'Tax'
                )}
                {renderDocumentRow('', 'taxReturnsAdditional', 'Tax')}

                {/* Tax Extensions */}
                <h4 className='text-md font-bold mt-6 mb-2 underline'>
                    Tax Extensions
                </h4>
                {renderDocumentRow(
                    `Business Tax Extension ${new Date().getFullYear() - 1}`,
                    `businessTaxExtension${new Date().getFullYear() - 1}`
                )}
                {renderDocumentRow(
                    `Personal Tax Extension ${new Date().getFullYear() - 1}`,
                    `personalTaxExtension${new Date().getFullYear() - 1}`
                )}

                {/* Miscellaneous Documents */}
                <h4 className='text-lg font-bold mt-8 mb-2 underline'>
                    Miscellaneous Documents
                </h4>
                {renderDocumentRow(
                    "Driver's License Front",
                    'driversLicenseFront',
                    true
                )}
                {renderDocumentRow(
                    `P & L Statement ${new Date().getFullYear() - 1}`,
                    `plStatement${new Date().getFullYear() - 1}`
                )}
                {renderDocumentRow(
                    `Accounts Receivable ${new Date().getFullYear() - 1}`,
                    `accountsReceivable${new Date().getFullYear() - 1}`
                )}
                {renderDocumentRow(
                    `Accounts Payables ${new Date().getFullYear() - 1}`,
                    `accountsPayables${new Date().getFullYear() - 1}`
                )}

                {renderAdditionalFiles(
                    additionalFiles?.miscellaneousAdditional || [],
                    'miscellaneousAdditional',
                    'Misc'
                )}
                {renderDocumentRow('', 'miscellaneousAdditional', 'Misc')}
            </section>
            {submitStatusMessage && (
                <div
                    className={`mt-4 p-4 rounded-md ${
                        submitStatusMessage.toLowerCase().includes('error')
                            ? 'bg-red-100 border border-red-400 text-red-700'
                            : 'bg-green-100 border border-green-400 text-green-700'
                    } font-medium text-sm`}
                >
                    {submitStatusMessage}
                </div>
            )}
        </div>
    )
}

export default DealDetailPage
