import React, { useState, useEffect } from 'react'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../config/firebaseConfig'
import Loading from '../components/Loading'
import moment from 'moment'

const PortalPage = ({ user, setSelectedDeal, setDetailModalOpen }) => {
    const [deals, setDeals] = useState([])
    const [loading, setLoading] = useState(true)
    // const [location, navigate] = useLocation(); // If you're not using 'location', replace it with an underscore.

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const fetchDeals = async () => {
            const dealsQuery = collection(db, 'dealsDev')
            const querySnapshot = await getDocs(dealsQuery)
            const dealsData = querySnapshot.docs
                .map((doc) => ({ id: doc.id, ...doc.data() })) // Map the documents to an array of objects
                .filter((doc) => doc.userEmail === user?.email) // Filter by user email
                .sort(
                    (a, b) =>
                        b.dateSubmitted.toDate() - a.dateSubmitted.toDate()
                ) // Sort by dateSubmitted

            setDeals(dealsData)
            setLoading(false)
        }

        fetchDeals()
        // eslint-disable-next-line
    }, [user])

    if (loading) {
        return (
            <div className='h-screen'>
                <Loading theme='primary' />
            </div>
        )
    }

    const handleViewDeal = (deal) => {
        window.location = `/deal/modify/${deal.id}`
        setSelectedDeal(deal)
        setDetailModalOpen(true)
    }

    return (
        <div className='bg-themeColorPrimary3 min-h-full flex flex-col w-full px-6 pt-6'>
            <div className='mt-[200px] md:mt-[130px] lg:mt-[130px]'>
                <h1 className='text-4xl font-bold my-4 mb-[30px]'>
                    Your Deals
                </h1>
                {deals.length > 0 ? (
                    <table className='w-full px-6'>
                        <thead>
                            <tr className='text-center text-xl'>
                                <th className='pb-4'>Date Submitted</th>
                                <th className='pb-4'>Business</th>
                                <th className='pb-4'>Referrer</th>
                                <th className='pb-4'>Status</th>
                                <th className='pb-4'>Amount Approved</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deals.map((deal) => (
                                <tr
                                    key={deal.id}
                                    className='mb-4 shadow-lg rounded-2xl border w-full hover:shadow-xl hover:border-themeColorPrimary transition-all'
                                >
                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                        {moment(
                                            (
                                                deal?.dateSubmitted || moment()
                                            ).toDate()
                                        ).format('MM - DD - YYYY')}
                                    </td>
                                    <td
                                        className='text-base text-center mb-2 py-4 underline cursor-pointer font-bold'
                                        onClick={() => handleViewDeal(deal)}
                                    >
                                        {deal?.businessName}
                                    </td>
                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                        {deal?.referrer}
                                    </td>
                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                        {deal?.status}
                                    </td>
                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                        {deal?.amountApproved
                                            ? `$${deal.amountApproved.toLocaleString()}`
                                            : '-'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div>
                        <table className='w-full px-6'>
                            <thead>
                                <tr className='text-center text-xl'>
                                    <th className='pb-4'>Date Submitted</th>
                                    <th className='pb-4'>Business</th>
                                    <th className='pb-4'>Referrer</th>
                                    <th className='pb-4'>Status</th>
                                    <th className='pb-4'>Amount Approved</th>
                                </tr>
                            </thead>
                        </table>
                        <p className='w-full text-center mt-4 text-2xl font-bold text-red-600'>
                            No deals found!
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PortalPage
