import { createUserWithEmailAndPassword } from 'firebase/auth'
import React, { useState } from 'react'
import { auth, db } from '../config/firebaseConfig'
import { BiSolidHide, BiSolidShow } from 'react-icons/bi'
import axios from 'axios'
import { Upload } from 'lucide-react'
import { useLocation } from 'wouter'
import { sendNewRegistrationEmailText } from '../utils/helpers'
import { doc, serverTimestamp, setDoc } from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'

const FileUploadField = ({
    label,
    name,
    accept = '.pdf,.jpg,.jpeg,.png',
    fieldStyles,
    handleFileChange,
    isUploading,
    required = false,
}) => {
    const [isDragging, setIsDragging] = useState(false)
    const [files, setFiles] = useState(null)

    const handleDrop = (e) => {
        e.preventDefault()
        setIsDragging(false)
        const droppedFiles = Array.from(e.dataTransfer.files)
        if (droppedFiles.length > 0) {
            handleFileChange(name, droppedFiles[0])
            setFiles(droppedFiles[0])
        }
    }

    const handleDragOver = (e) => {
        e.preventDefault()
        setIsDragging(true)
    }

    const handleDragLeave = () => {
        setIsDragging(false)
    }

    const handleInputChange = (e) => {
        const file = e.target.files[0]
        if (file) {
            handleFileChange(name, file)
            setFiles(file)
        }
    }

    const removeFile = () => {
        setFiles(null)
        handleFileChange(name, null)
    }

    return (
        <div className='mb-4'>
            <label
                className='block text-grey-darker text-sm font-bold mb-2'
                htmlFor={name}
            >
                {label} {required && <span className='text-red-500'>*</span>}
            </label>
            <div
                className={`border-2 border-dashed rounded-lg p-4 transition-colors ${
                    isDragging
                        ? 'border-themeColorPrimary bg-blue-50'
                        : 'border-gray-300'
                } ${
                    fieldStyles[name]?.outline ? fieldStyles[name].outline : ''
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                {!files ? (
                    <div className='flex flex-col items-center justify-center gap-2'>
                        <Upload className='w-8 h-8 text-gray-400' />
                        <p className='text-sm text-gray-500 text-center'>
                            {isDragging
                                ? 'Drop file here'
                                : 'Drag & drop file here or'}
                        </p>
                        <label className='cursor-pointer px-4 py-2 bg-gray-50 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md border border-gray-300'>
                            Browse Files
                            <input
                                type='file'
                                id={name}
                                name={name}
                                onChange={handleInputChange}
                                accept={accept}
                                className='hidden'
                                required={required}
                            />
                        </label>
                        <p className='text-xs text-gray-500 mt-1'>
                            Accepted files: {accept.replace(/\./g, ' ')}
                        </p>
                    </div>
                ) : (
                    <div className='flex items-center justify-between bg-gray-50 p-2 rounded-md'>
                        <span className='text-sm truncate max-w-xs'>
                            {files.name}
                        </span>
                        <button
                            onClick={removeFile}
                            className='text-red-600 hover:text-red-800 font-medium ml-2 text-xs'
                            type='button'
                        >
                            Remove
                        </button>
                    </div>
                )}
                {isUploading && (
                    <div className='mt-2 flex justify-center'>
                        <div className='animate-spin rounded-full h-5 w-5 border-2 border-themeColorPrimary border-t-transparent'></div>
                    </div>
                )}
            </div>
        </div>
    )
}

const initialStyles = {
    email: {},
    password: {},
    confirmPassword: {},
    companyName: {},
    dba: {},
    address: {},
    city: {},
    state: {},
    zip: {},
    officeNumber: {},
    ein: {},
    primaryContactPerson: {},
    telephone: {},
    contactEmail: {},
    driversLicense: {},
    w9Form: {},
}

const validStyle = { outline: '2px solid #078586' }
const invalidStyle = { outline: '2px solid red' }

const CreateAccount = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        companyName: '',
        dba: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        officeNumber: '',
        ein: '',
        primaryContactPerson: '',
        telephone: '',
        contactEmail: '',
        driversLicenseMetadata: {
            file: null,
            timestamp: null,
            originalName: '',
            url: '',
        },
        w9FormMetadata: {
            file: null,
            timestamp: null,
            originalName: '',
            url: '',
        },
    })

    const [userId] = useState(uuidv4())

    const [hidePass, setHidePass] = useState(true)
    const [loading, setLoading] = useState(false)
    const [submitStatusMessage, setSubmitStatusMessage] = useState('')
    const [location, navigate] = useLocation()
    const [errorMessage, setErrorMessage] = useState('')
    const [fieldStyles, setFieldStyles] = useState(initialStyles)
    const [uploadingStates, setUploadingStates] = useState({
        driversLicense: false,
        w9Form: false,
    })

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (!file || !(file instanceof Blob)) {
                resolve('N/A')
                return
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result.split(',')[1])
            reader.onerror = (error) => reject(error)
        })
    }

    const handleFileChange = async (fieldName, file) => {
        if (!file) {
            setFormData((prev) => ({
                ...prev,
                [`${fieldName}Metadata`]: {
                    file: null,
                    timestamp: null,
                    originalName: '',
                    url: '',
                },
            }))
            return
        }

        // Validate file size (e.g., 10MB limit)
        const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10MB in bytes
        if (file.size > MAX_FILE_SIZE) {
            alert('File is too large. Maximum size is 10MB.')
            return
        }

        try {
            setUploadingStates((prev) => ({ ...prev, [fieldName]: true }))

            const fileData = {
                name: fieldName,
                content: await fileToBase64(file),
                type: file.type,
                originalName: file.name,
            }

            // Upload to Firebase through backend
            const response = await axios.post(
                'https://us-central1-quidity-app.cloudfunctions.net/uploadFile',
                // 'http://127.0.0.1:5001/quidity-app/us-central1/uploadFile',
                {
                    file: fileData,
                    userId,
                    fileCategory: fieldName,
                    isUserRegistration: true,
                }
            )

            setFormData((prev) => ({
                ...prev,
                [`${fieldName}Metadata`]: {
                    file,
                    timestamp: response.data.timestamp,
                    url: response.data.fileUrl,
                    originalName: file.name,
                },
            }))

            setFieldStyles((prev) => ({
                ...prev,
                [fieldName]: validStyle,
            }))
        } catch (error) {
            console.error('Error uploading file:', error)
            alert('File upload failed. Please try again.')
            setFieldStyles((prev) => ({
                ...prev,
                [fieldName]: invalidStyle,
            }))
        } finally {
            setUploadingStates((prev) => ({ ...prev, [fieldName]: false }))
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target

        if (
            name === 'officeNumber' ||
            name === 'telephone' ||
            name === 'zip' ||
            name === 'ein'
        ) {
            const isIntegerOrEmpty = /^-?\d*$/.test(value)
            if (!isIntegerOrEmpty) return
        }

        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }))

        handleBlur(e)
    }

    const validateField = (name, value) => {
        let isValid = false

        switch (name) {
            case 'email':
            case 'contactEmail':
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                isValid = emailRegex.test(value)
                break
            case 'password':
                isValid = value.length >= 8
                break
            case 'confirmPassword':
                isValid = value === formData.password && value !== ''
                break
            case 'zip':
                isValid = /^\d{5}$/.test(value)
                break
            case 'ein':
                isValid = /^\d{9}$/.test(value)
                break
            case 'officeNumber':
            case 'telephone':
                isValid = /^\d{10}$/.test(value)
                break
            case 'companyName':
            case 'address':
            case 'city':
            case 'state':
            case 'primaryContactPerson':
                isValid = value?.trim() !== ''
                break
            default:
                isValid = true
        }

        return isValid
    }

    const handleBlur = (e) => {
        const { name, value } = e.target
        const isValid = validateField(name, value)

        setFieldStyles((prev) => ({
            ...prev,
            [name]: isValid ? validStyle : invalidStyle,
        }))
    }

    const handleFocus = (e) => {
        const { name } = e.target
        handleBlur(e)
    }

    const areAllFieldsFilled = () => {
        const requiredFields = [
            'email',
            'password',
            'confirmPassword',
            'companyName',
            'address',
            'city',
            'state',
            'zip',
            'officeNumber',
            'ein',
            'primaryContactPerson',
            'telephone',
            'contactEmail',
        ]

        return (
            requiredFields.every((field) =>
                validateField(field, formData[field])
            ) &&
            formData.driversLicenseMetadata.url &&
            formData.w9FormMetadata.url
        )
    }

    const registerUser = async () => {
        try {
            if (!areAllFieldsFilled()) {
                setErrorMessage('Please fill in all required fields correctly.')
                return
            }

            setSubmitStatusMessage('Creating account...')
            setLoading(true)

            const userCredential = await createUserWithEmailAndPassword(
                auth,
                formData.email,
                formData.password
            )

            if (!userCredential.user) {
                throw new Error('Failed to create user.')
            }

            const name = formData.primaryContactPerson.split(' ')
            const firstName = name[0] || ''
            const lastName = name[1] || ''

            const userRef = doc(db, 'usersDev', userCredential.user.uid)
            await setDoc(userRef, {
                email: userCredential.user.email,
                userId,
                uid: userCredential.user.uid,
                firstName,
                lastName,
                companyName: formData.companyName,
                dba: formData.dba,
                address: formData.address,
                city: formData.city,
                state: formData.state,
                zip: formData.zip,
                officeNumber: formData.officeNumber,
                ein: formData.ein,
                telephone: formData.telephone,
                primaryContactPerson: formData.primaryContactPerson,
                contactEmail: formData.contactEmail,
                createdAt: serverTimestamp(),
                driversLicense: {
                    url: formData.driversLicenseMetadata.url,
                    uploadedAt: formData.driversLicenseMetadata.timestamp,
                    fileName: formData.driversLicenseMetadata.originalName,
                },
                w9Form: {
                    url: formData.w9FormMetadata.url,
                    uploadedAt: formData.w9FormMetadata.timestamp,
                    fileName: formData.w9FormMetadata.originalName,
                },
            })

            await sendNewRegistrationEmailText(
                formData.companyName,
                `${firstName} ${lastName}`,
                formData.telephone,
                formData.contactEmail
            )

            setSubmitStatusMessage('Account created successfully!')
            navigate('/terms')
        } catch (error) {
            let message = 'Failed to create account. Please try again.'

            if (error.code === 'auth/email-already-in-use') {
                message =
                    'An account with this email already exists. Please log in or use a different email.'
            } else if (error.code === 'auth/weak-password') {
                message = 'Password should be at least 8 characters long.'
            } else if (error.code === 'auth/invalid-email') {
                message = 'Invalid email format.'
            }

            setErrorMessage(message)
            console.error('Error creating account:', error)
        } finally {
            setLoading(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        await registerUser()
    }

    return (
        <div className='bg-themeColorPrimary3 min-h-screen flex flex-col justify-center items-center'>
            <div className='mt-[120px] bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4 w-[95%] md:w-[600px] lg:w-[600px]'>
                <h1 className='block w-full text-center text-grey-darker text-[28px] mb-6 font-bold text-themeColorPrimary2'>
                    Create an Account
                </h1>
                <form onSubmit={handleSubmit}>
                    {/* Email Field */}
                    <div className='mb-4'>
                        <label className='block text-grey-darker text-sm font-bold mb-2'>
                            Email *
                        </label>
                        <input
                            style={fieldStyles.email}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            type='email'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            className='themed-input'
                            required
                        />
                    </div>

                    {/* Password Field */}
                    <div className='mb-4'>
                        <label className='block text-grey-darker text-sm font-bold mb-2'>
                            Password (min 8 characters) *
                        </label>
                        <div
                            style={fieldStyles.password}
                            className='flex items-center shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker focus-within:outline outline-themeColorPrimary'
                        >
                            <input
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                type={hidePass ? 'password' : 'text'}
                                name='password'
                                value={formData.password}
                                onChange={handleChange}
                                className='outline-none w-full'
                                required
                            />
                            <div
                                className='cursor-pointer hover:opacity-[80%]'
                                onClick={() => setHidePass(!hidePass)}
                            >
                                {hidePass ? (
                                    <BiSolidShow size={20} />
                                ) : (
                                    <BiSolidHide size={20} />
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Confirm Password Field */}
                    <div className='mb-4'>
                        <label className='block text-grey-darker text-sm font-bold mb-2'>
                            Confirm Password *
                        </label>
                        <div
                            style={fieldStyles.confirmPassword}
                            className='flex items-center shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker focus-within:outline outline-themeColorPrimary'
                        >
                            <input
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                type={hidePass ? 'password' : 'text'}
                                name='confirmPassword'
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                className='outline-none w-full'
                                required
                            />
                        </div>
                    </div>

                    {/* Company Name Field */}
                    <div className='mb-4'>
                        <label className='block text-grey-darker text-sm font-bold mb-2'>
                            Company Name *
                        </label>
                        <input
                            style={fieldStyles.companyName}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            type='text'
                            name='companyName'
                            value={formData.companyName}
                            onChange={handleChange}
                            className='themed-input'
                            required
                        />
                    </div>

                    {/* DBA Field */}
                    <div className='mb-4'>
                        <label className='block text-grey-darker text-sm font-bold mb-2'>
                            DBA
                        </label>
                        <input
                            style={fieldStyles.dba}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            type='text'
                            name='dba'
                            value={formData.dba}
                            onChange={handleChange}
                            className='themed-input'
                        />
                    </div>

                    {/* Address Field */}
                    <div className='mb-4'>
                        <label className='block text-grey-darker text-sm font-bold mb-2'>
                            Address *
                        </label>
                        <input
                            style={fieldStyles.address}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            type='text'
                            name='address'
                            value={formData.address}
                            onChange={handleChange}
                            className='themed-input'
                            required
                        />
                    </div>

                    {/* City Field */}
                    <div className='mb-4'>
                        <label className='block text-grey-darker text-sm font-bold mb-2'>
                            City *
                        </label>
                        <input
                            style={fieldStyles.city}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            type='text'
                            name='city'
                            value={formData.city}
                            onChange={handleChange}
                            className='themed-input'
                            required
                        />
                    </div>

                    {/* State Field */}
                    <div className='mb-4'>
                        <label className='block text-grey-darker text-sm font-bold mb-2'>
                            State *
                        </label>
                        <select
                            style={fieldStyles.state}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            name='state'
                            value={formData.state}
                            onChange={handleChange}
                            className='input input-bordered w-full col-span-1 themed-input cursor-pointer'
                            required
                        >
                            <option value=''>Select State</option>
                            {[
                                'AL',
                                'AK',
                                'AZ',
                                'AR',
                                'CA',
                                'CO',
                                'CT',
                                'DE',
                                'FL',
                                'GA',
                                'HI',
                                'ID',
                                'IL',
                                'IN',
                                'IA',
                                'KS',
                                'KY',
                                'LA',
                                'ME',
                                'MD',
                                'MA',
                                'MI',
                                'MN',
                                'MS',
                                'MO',
                                'MT',
                                'NE',
                                'NV',
                                'NH',
                                'NJ',
                                'NM',
                                'NY',
                                'NC',
                                'ND',
                                'OH',
                                'OK',
                                'OR',
                                'PA',
                                'RI',
                                'SC',
                                'SD',
                                'TN',
                                'TX',
                                'UT',
                                'VT',
                                'VA',
                                'WA',
                                'WV',
                                'WI',
                                'WY',
                            ].map((state) => (
                                <option key={state} value={state}>
                                    {state}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Zip Field */}
                    <div className='mb-4'>
                        <label className='block text-grey-darker text-sm font-bold mb-2'>
                            Zip *
                        </label>
                        <input
                            style={fieldStyles.zip}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            type='text'
                            name='zip'
                            maxLength={5}
                            value={formData.zip}
                            onChange={handleChange}
                            className='themed-input'
                            required
                        />
                    </div>

                    {/* Office Number Field */}
                    <div className='mb-4'>
                        <label className='block text-grey-darker text-sm font-bold mb-2'>
                            Office Number *
                        </label>
                        <input
                            style={fieldStyles.officeNumber}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            type='text'
                            name='officeNumber'
                            maxLength={10}
                            value={formData.officeNumber}
                            onChange={handleChange}
                            className='themed-input'
                            required
                        />
                    </div>

                    {/* EIN Field */}
                    <div className='mb-4'>
                        <label className='block text-grey-darker text-sm font-bold mb-2'>
                            EIN (Employer Identification Number) *
                        </label>
                        <input
                            style={fieldStyles.ein}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            type='text'
                            name='ein'
                            maxLength={9}
                            value={formData.ein}
                            onChange={handleChange}
                            className='themed-input'
                            required
                        />
                    </div>

                    {/* Primary Contact Person Field */}
                    <div className='mb-4'>
                        <label className='block text-grey-darker text-sm font-bold mb-2'>
                            Primary Contact Person *
                        </label>
                        <input
                            style={fieldStyles.primaryContactPerson}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            type='text'
                            name='primaryContactPerson'
                            value={formData.primaryContactPerson}
                            onChange={handleChange}
                            className='themed-input'
                            required
                        />
                    </div>

                    {/* Telephone Field */}
                    <div className='mb-4'>
                        <label className='block text-grey-darker text-sm font-bold mb-2'>
                            Telephone *
                        </label>
                        <input
                            style={fieldStyles.telephone}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            type='text'
                            name='telephone'
                            maxLength={10}
                            value={formData.telephone}
                            onChange={handleChange}
                            className='themed-input'
                            required
                        />
                    </div>

                    {/* Contact Email Field */}
                    <div className='mb-4'>
                        <label className='block text-grey-darker text-sm font-bold mb-2'>
                            Contact Email *
                        </label>
                        <input
                            style={fieldStyles.contactEmail}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            type='email'
                            name='contactEmail'
                            value={formData.contactEmail}
                            onChange={handleChange}
                            className='themed-input'
                            required
                        />
                    </div>

                    {/* File Upload Fields */}
                    <FileUploadField
                        label="Driver's License"
                        name='driversLicense'
                        accept='.jpg,.jpeg,.png,.pdf'
                        fieldStyles={fieldStyles}
                        handleFileChange={handleFileChange}
                        isUploading={uploadingStates.driversLicense}
                        required={true}
                    />

                    <FileUploadField
                        label='W-9 Form'
                        name='w9Form'
                        accept='.jpg,.jpeg,.png,.pdf'
                        fieldStyles={fieldStyles}
                        handleFileChange={handleFileChange}
                        isUploading={uploadingStates.w9Form}
                        required={true}
                    />

                    {/* Submit Button */}
                    <div className='flex items-center justify-between'>
                        <button
                            type='submit'
                            className='mt-4 bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed'
                            disabled={!areAllFieldsFilled() || loading}
                        >
                            {loading ? (
                                <div className='flex items-center'>
                                    <div className='animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent mr-2' />
                                    Creating Account
                                </div>
                            ) : (
                                'Create Account'
                            )}
                        </button>
                    </div>

                    {submitStatusMessage && (
                        <p className='mt-4 font-bold text-themeColorPrimary2'>
                            {submitStatusMessage}
                        </p>
                    )}

                    {errorMessage && (
                        <p className='mt-4 font-bold text-red-500'>
                            {errorMessage}
                        </p>
                    )}
                </form>
            </div>
        </div>
    )
}

export default CreateAccount
