import React, { useEffect, useState } from 'react'
import {
    collection,
    getDocs,
    orderBy,
    query,
    doc,
    getDoc,
} from 'firebase/firestore'
import { db } from '../config/firebaseConfig'
import moment from 'moment'
import Loading from '../components/Loading'
import { useLocation } from 'wouter'
import { MessageSquare, X } from 'lucide-react'

const formatTimestamp = (timestamp) => {
    try {
        const date = new Date(timestamp)
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
    } catch (error) {
        return 'Invalid date'
    }
}

const AdminPage = ({
    user,
    selectedDeal,
    setSelectedDeal,
    selectedAccount,
    setSelectedAccount,
    setDetailModalOpen,
    setAccountDetailsModalOpen,
}) => {
    const [deals, setDeals] = useState([])
    const [accounts, setAccounts] = useState([])
    const [messages, setMessages] = useState([])
    const [fetching, setFetching] = useState(true)
    const [location, setLocation] = useLocation()
    const [activeTab, setActiveTab] = useState('submissions')
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedMessages, setSelectedMessages] = useState(null)
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false)

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const tab = params.get('tab') || 'submissions'
        setActiveTab(tab)
    }, [location])

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch all accounts first
                const accountsQuery = query(
                    collection(db, 'usersDev'),
                    orderBy('createdAt', 'asc')
                )
                const accountsSnapshot = await getDocs(accountsQuery)
                const accountsData = accountsSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setAccounts(accountsData)

                // Fetch all messages
                const allMessages = []
                for (const account of accountsData) {
                    if (account.messages) {
                        allMessages.push(...account.messages)
                    }
                }
                setMessages(allMessages)

                // Fetch all deals
                const dealsQuery = query(
                    collection(db, 'dealsDev'),
                    orderBy('dateSubmitted', 'asc')
                )
                const dealsSnapshot = await getDocs(dealsQuery)

                // Process each deal and fetch associated user data
                const dealsPromises = dealsSnapshot.docs
                    .reverse()
                    .map(async (dealDoc) => {
                        const dealData = dealDoc.data()

                        // Fetch user document using userId
                        const userDoc = await getDoc(
                            doc(db, 'usersDev', dealData.userId)
                        )
                        const companyName = userDoc.exists()
                            ? userDoc.data().companyName
                            : ''

                        return {
                            id: dealDoc.id,
                            ...dealData,
                            companyName,
                        }
                    })

                const dealsData = await Promise.all(dealsPromises)
                setDeals(dealsData)
            } catch (error) {
                console.error('Error fetching data:', error)
            } finally {
                setFetching(false)
            }
        }

        fetchData()
    }, [user, selectedDeal, selectedAccount])

    const highlightText = (text, highlight) => {
        if (!highlight.trim()) return text
        const regex = new RegExp(`(${highlight})`, 'gi')
        const parts = text.split(regex)
        return parts.map((part, i) =>
            regex.test(part) ? (
                <mark key={i} className='bg-yellow-200'>
                    {part}
                </mark>
            ) : (
                part
            )
        )
    }

    const handleViewMessages = (deal) => {
        const dealMessages = messages.filter((msg) => msg.dealId === deal.id)
        setSelectedMessages({ deal, messages: dealMessages })
        setIsMessageModalOpen(true)
    }

    const MessageModal = () => {
        if (!isMessageModalOpen || !selectedMessages) return null

        const { deal, messages: dealMessages } = selectedMessages
        const unreadCount = dealMessages.filter((msg) => !msg.read).length

        return (
            <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000]'>
                <div className='bg-white rounded-lg w-full max-w-2xl mx-4'>
                    <div className='flex justify-between items-center p-4 border-b border-gray-200'>
                        <h2 className='text-xl font-bold'>
                            Messages for {deal.businessName}
                        </h2>
                        <button
                            onClick={() => setIsMessageModalOpen(false)}
                            className='text-gray-500 hover:text-gray-700'
                        >
                            <X className='h-6 w-6' />
                        </button>
                    </div>

                    <div className='max-h-[400px] overflow-y-auto p-4'>
                        {dealMessages.length > 0 ? (
                            <div className='space-y-4'>
                                {dealMessages
                                    .sort((a, b) => b.timestamp - a.timestamp)
                                    .map((message, index) => (
                                        <div
                                            key={index}
                                            className={`p-4 rounded-lg border ${
                                                message.read
                                                    ? 'bg-gray-50'
                                                    : 'bg-white border-blue-200'
                                            }`}
                                        >
                                            <div className='flex justify-between items-start mb-2'>
                                                <span className='font-medium'>
                                                    {message.read
                                                        ? 'Read'
                                                        : 'Unread'}
                                                </span>
                                                <span className='text-sm text-gray-500'>
                                                    {formatTimestamp(
                                                        message.timestamp
                                                    )}
                                                </span>
                                            </div>
                                            <p className='text-gray-800'>
                                                {message.message}
                                            </p>
                                        </div>
                                    ))}
                            </div>
                        ) : (
                            <div className='text-center text-gray-500 py-8'>
                                No messages for this deal
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    const filteredDeals = deals.filter((deal) =>
        deal.businessName.toLowerCase().includes(searchTerm.toLowerCase())
    )

    const handleViewDeal = (deal) => {
        window.location = `/deal/modify/${deal.id}`
        setSelectedDeal(deal)
    }

    const handleViewAccount = (account) => {
        setSelectedAccount(account)
        setAccountDetailsModalOpen(true)
    }

    const handleTabChange = (tab) => {
        setActiveTab(tab)
        const currentPath = window.location.pathname
        const newUrl = `${currentPath}?tab=${tab}`
        window.history.pushState({}, '', newUrl)
    }

    return (
        <div className='bg-themeColorPrimary3 min-h-full flex flex-col w-full px-6 pt-6'>
            <div className='mt-[200px] md:mt-[130px] lg:mt-[130px]'>
                <div className='flex justify-center mb-6'>
                    <button
                        onClick={() => handleTabChange('submissions')}
                        className={`px-4 py-2 text-lg font-bold ${
                            activeTab === 'submissions'
                                ? 'border-b-4 border-[#078586]'
                                : ''
                        }`}
                    >
                        Submissions
                    </button>
                    <button
                        onClick={() => handleTabChange('iso')}
                        className={`px-4 py-2 text-lg font-bold ml-4 ${
                            activeTab === 'iso'
                                ? 'border-b-4 border-[#078586]'
                                : ''
                        }`}
                    >
                        ISO
                    </button>
                </div>

                {!fetching ? (
                    <>
                        {activeTab === 'submissions' && (
                            <div>
                                <h1 className='text-4xl font-bold my-4 mb-[30px]'>
                                    All Deals
                                </h1>
                                <div className='mb-6'>
                                    <input
                                        type='text'
                                        placeholder='Search by business name...'
                                        value={searchTerm}
                                        onChange={(e) =>
                                            setSearchTerm(e.target.value)
                                        }
                                        className='w-full max-w-md px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#078586]'
                                    />
                                </div>
                                {filteredDeals.length > 0 ? (
                                    <table className='w-full px-6'>
                                        <thead>
                                            <tr className='text-center text-xl'>
                                                <th className='pb-4'>Date</th>
                                                <th className='pb-4'>
                                                    Business Name
                                                </th>
                                                <th className='pb-4'>ISO</th>
                                                <th className='pb-4'>
                                                    ISO Referrer
                                                </th>
                                                <th className='pb-4'>Status</th>
                                                <th className='pb-4'>
                                                    Amount Approved
                                                </th>
                                                <th className='pb-4'>
                                                    Messages
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredDeals.map((deal) => (
                                                <tr
                                                    key={deal.id}
                                                    className='mb-4 shadow-lg rounded-2xl border w-full cursor-pointer hover:shadow-xl hover:border-themeColorPrimary transition-all'
                                                >
                                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                                        {moment(
                                                            deal.dateSubmitted.toDate()
                                                        ).format(
                                                            'MM - DD - YYYY'
                                                        )}
                                                    </td>
                                                    <td
                                                        className='font-semibold text-base text-center mb-2 py-4 underline'
                                                        onClick={() =>
                                                            handleViewDeal(deal)
                                                        }
                                                    >
                                                        {highlightText(
                                                            deal.businessName,
                                                            searchTerm
                                                        )}
                                                    </td>
                                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                                        {deal.companyName}
                                                    </td>
                                                    <td className='text-base text-center mb-2 py-4 cursor-pointer font-bold'>
                                                        {deal.referrer}
                                                    </td>
                                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                                        {deal.status}
                                                    </td>
                                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                                        {deal?.amountApproved
                                                            ? `$${deal.amountApproved.toLocaleString()}`
                                                            : '-'}
                                                    </td>
                                                    <td className='text-center py-4'>
                                                        <div
                                                            className='relative inline-flex items-center cursor-pointer'
                                                            onClick={() =>
                                                                handleViewMessages(
                                                                    deal
                                                                )
                                                            }
                                                        >
                                                            <MessageSquare className='h-5 w-5 text-gray-500 hover:text-gray-700' />
                                                            {messages.filter(
                                                                (msg) =>
                                                                    msg.dealId ===
                                                                        deal.id &&
                                                                    !msg.read
                                                            ).length > 0 && (
                                                                <span className='absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center'>
                                                                    {
                                                                        messages.filter(
                                                                            (
                                                                                msg
                                                                            ) =>
                                                                                msg.dealId ===
                                                                                    deal.id &&
                                                                                !msg.read
                                                                        ).length
                                                                    }
                                                                </span>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div>
                                        <table className='w-full px-6'>
                                            <thead>
                                                <tr className='text-center text-xl'>
                                                    <th className='pb-4'>
                                                        Date Submitted
                                                    </th>
                                                    <th className='pb-4'>
                                                        Business Name
                                                    </th>
                                                    <th className='pb-4'>
                                                        ISO
                                                    </th>
                                                    <th className='pb-4'>
                                                        ISO Referrer
                                                    </th>
                                                    <th className='pb-4'>
                                                        Status
                                                    </th>
                                                    <th className='pb-4'>
                                                        Amount Approved
                                                    </th>
                                                    <th className='pb-4'>
                                                        Messages
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                        <p className='w-full text-center mt-4 text-2xl font-bold text-red-600'>
                                            No deals found!
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}

                        {activeTab === 'iso' && (
                            <div>
                                <h1 className='text-4xl font-bold my-4 mb-[30px]'>
                                    All ISO
                                </h1>
                                {accounts.length > 0 ? (
                                    <table className='w-full px-6'>
                                        <thead>
                                            <tr className='text-center text-xl'>
                                                <th className='pb-4'>
                                                    ISO Name
                                                </th>
                                                <th className='pb-4'>
                                                    ISO Phone Number
                                                </th>
                                                <th className='pb-4'>
                                                    ISO Email
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {accounts.map((account) => (
                                                <tr
                                                    key={account.id}
                                                    className='mb-4 shadow-lg rounded-2xl border w-full'
                                                >
                                                    <td
                                                        className='font-semibold text-base text-center mb-2 py-4 underline cursor-pointer'
                                                        onClick={() =>
                                                            handleViewAccount(
                                                                account
                                                            )
                                                        }
                                                    >
                                                        {account.companyName}
                                                    </td>
                                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                                        {account.telephone}
                                                    </td>
                                                    <td className='font-semibold text-base text-center mb-2 py-4'>
                                                        {account.email}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p className='w-full text-center mt-4 text-2xl font-bold text-red-600'>
                                        No accounts found!
                                    </p>
                                )}
                            </div>
                        )}
                    </>
                ) : (
                    <div className='h-[300px] overflow-hidden'>
                        <Loading theme='primary' />
                    </div>
                )}
            </div>

            {/* Message Modal */}
            <MessageModal />
        </div>
    )
}

export default AdminPage
